main#chargezen_main_affiliate_page_container {
    background: #f3f3f300;
}

main#chargezen_main_affiliate_page_container h1.chargezen_page__title {
    text-align: center;
    margin: 35px 0;
    font-size: 29px;
    line-height: 1.43;
    font-family: var(--text-font-family);
    font-weight: 700;
    font-style: normal;
    color: #303030;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content.rte {
    padding-bottom: 90px;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content .main_header_content_wrapper p {
    font-size: 30px;
    max-width: 70%;
    letter-spacing: 0;
    margin: 25px auto 0;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content p .main_header_wrapper {
    background-color: #f8f8f8;
    padding: 75px 40px 50px 100px;
    text-align: center;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    letter-spacing: 0;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content.rte h2,
main#chargezen_main_affiliate_page_container .chargezen_page__content.rte h1 {
    margin: 0;
    font-size: 85px;
    line-height: 1;
    letter-spacing: 0;
    text-transform: uppercase;
}

h3.loyalty_points_faqs_heading.heading_h2 {
    margin: 0 auto 60px;
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0;
    text-align: center;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content p .main_header_wrapper h2 {
    font-size: 62px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0;
    text-transform: uppercase;
}


.how_to_earn_commission_container {
    padding: 40px 0;
    max-width: 1100px;
    margin: 0 auto;
}

.how_to_earn_commission_container h3 {
    text-align: center;
    padding: 10px 0;
    font-size: 35px;
    background: #000000;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
}

.how_to_earn_commission_container .earn_commission_list_wrapper {
    background: #fff;
    padding: 30px 50px;
    justify-content: space-between;
    display: flex;
    gap: 35px;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list {
    flex: 1;
    text-align: center;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list .icon_wrapper svg {
    height: 60px;
    width: 60px;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list .text_wrapper .get_your_commission_button:hover {
    opacity: 0.8;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list .text_wrapper .get_your_commission_button {
    background-color: #ADD8E6;
    border: 0;
    margin: 16px 0 0;
    font-size: 16px;
    max-width: 150px;
    border-radius: 10px;
    padding: 5px 15px;
    line-height: 1.3;
    font-weight: 600;
}

.faq_section_container h2 {
    font-weight: 700;
    font-style: normal;
    color: #303030;
    font-size: 26px !important;
    text-align: center;
    text-transform: capitalize !important;
}

.faq_section_container h2 strong {
    border-bottom: 2px solid #000;
    padding-bottom: 2px;
}

main#chargezen_main_affiliate_page_container {
    /* background: #f3f3f3; */
    padding-top: 35px;
}

main#chargezen_main_affiliate_page_container h1.chargezen_page__title {
    margin-top: 0;
}

.faq_section_container {
    max-width: 1480px;
    padding: 0;
    margin: 0 auto;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content .how_to_earn_commission_container {
    max-width: 100%;
}

.faq_section_container ol {
    text-align: left;
    padding: 0 0 0 15px;
    margin-top: 0
}

.faq_section_container h2 {
    margin: 0 0 1.55em !important;
}

.faq_section_container ol li,
.faq_section_container p {
    font-size: 17px;
}

.faq_section_container p a {
    color: #000;
    opacity: 0.8;
}

.faq_section_container p a:hover {
    opacity: 1;
}

.faq_section_container ul li {
    margin: 0 0 5px;
    position: relative;
    font-size: 17px;
    line-height: 31px;
    list-style: none;
}

.faq_section_container ul li::after {
    left: -18px;
    top: 0.8em;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    content: '';
}

.faq_section_container ul {
    padding: 0 0 0 16px;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content .main_header_wrapper {
    max-width: 100% !important;
    box-sizing: border-box;
    padding: 75px 40px 50px 40px !important;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content.rte {
    max-width: 1480px;
    padding: 0 40px 90px;
    margin: 0 auto;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list p {
    font-size: 16px;
    line-height: 1.4;
    position: relative;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list>.text_wrapper p br {
    display: none;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list>.text_wrapper p {
    min-height: 45px;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list p::after {
    content: '';
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #c5c5c5;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list p a {
    color: #000000;
    text-decoration: none;
    font-weight: 600;
}

/* New Faqs */
.loyalty_points_faqs_container {
    max-width: 1010px;
    margin: 0 auto;
    padding: 50px 15px 70px;
}

.loyalty_points_faqs_container h2.loyalty_points_faqs_heading.heading_h2 {
    margin: 0 0 80px;
    justify-content: center;
}

.loyalty_points_faqs_container .accordion .accordion_content {
    background-color: #00000008;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
    padding: 30px;
    padding: 15px 30px;
    transition: 0.5s all ease;
}

.loyalty_points_faqs_container .accordion .accordion_item h3.accordion_item_header {
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

.loyalty_points_faqs_container .accordion .accordion_item h3.accordion_item_header {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #000000;
    font-family: var(--text-font-family);
    display: flex;
    justify-content: space-between;
}

.loyalty_points_faqs_container .accordion .accordion_item h3.accordion_item_header .accordion_item_header_symbol {
    height: 28px;
    width: 28px;
    padding: 4px;
    border: 2px solid #c4c4c4;
    position: relative;
    border-radius: 50%;
}

.loyalty_points_faqs_container .accordion .accordion_item h3.accordion_item_header .accordion_item_header_symbol .__symbol_vertical {
    width: 2px;
    height: 22px;
    position: absolute;
    background-color: #c4c4c4;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    transition: opacity .33s ease;
}

.loyalty_points_faqs_container .accordion .accordion_item h3.accordion_item_header .accordion_item_header_symbol .__symbol_horizontal {
    position: absolute;
    background-color: #c4c4c4;
    border-radius: 4px;
    width: 22px;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
}

.no_height {
    height: 0px !important;
    background-color: transparent;
    margin-bottom: 0 !important;
    padding: 0px !important;
    overflow: hidden !important;
}

.login_advertiser_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    padding: 45px 15px 55px 15px;
}

.header_logo_wrapper {
    display: flex;
    justify-content: center;
}

.header_logo_wrapper img {
    max-width: 158px;
    max-height: 71px;
    width: 100%;
    height: 100%;
    margin-bottom: 35px;
}

.body_content_wrapper .signup_link_wrapper {
    width: 100%;
}

.body_content_wrapper .signup_link_wrapper a.account_login_wrapper {
    max-width: 100%;
    width: 100%;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    padding: 22px 32px;
    border: 3px solid #00000080;
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.body_content_wrapper .signup_link_wrapper a.account_login_wrapper:hover {
    opacity: 0.5;
}

h4.advertiser_subheader_text {
    margin: 28px 0 24px;
}

.advertisement_content_lists {
    display: flex;
    flex-flow: column;
    gap: 5px;
}

.advertisement_content_lists .advertisement_list {
    display: flex;
    align-items: center;
    gap: 17px;
}

.advertisement_content_lists .advertisement_list .icon_wrapper {
    width: 18px;
    height: 18px;
}

.advertisement_content_lists .advertisement_list .icon_wrapper svg {
    width: 18px;
    height: 18px;
}

.advertisement_content_lists .advertisement_list>.advertisement_text_wrapper {
    color: #000;
    font-family: var(--text-font-family);
    font-size: 14.18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}

div#chargezen_affiliate_page,
div#chargezen_affiliate_page * {
    box-sizing: border-box;
}

.login_advertiser_wrapper>.chargezen_advertiser_container {
    width: 100%;
    max-width: 540px;
}

.login_advertiser_wrapper {
    margin-top: 20px;
}


main#chargezen_main_affiliate_page_container .chargezen_page__content.rte>div>p {
    margin: 0;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content .how_to_earn_commission_container {
    padding-top: 26px;
    background: #f8f8f8;
    padding-bottom: 0;
    margin-top: 26px;
}

.how_to_earn_commission_container .earn_commission_list_wrapper {
    background: transparent;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content.rte h1 {
    letter-spacing: -3px;
}

main#chargezen_main_affiliate_page_container .chargezen_page__content p .main_header_wrapper h2 {
    letter-spacing: -3px;
}

.accordion_content {
    padding: 10px 0px !important;
    background: transparent !important;
}

.accordion_content.no_height {
    padding: 0 !important;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list>.text_wrapper p {
    min-height: 65px;
}

.how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list>.text_wrapper p br {
    display: none;
}



@media(max-width:1365px) {
    .how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list>.text_wrapper p {
        min-height: 65px;
    }
}

@media(max-width:767px) {
    .loyalty_points_faqs_container {
        padding: 50px 15px 45px;
    }

    .loyalty_points_faqs_container .accordion .accordion_item h3.accordion_item_header {
        padding: 15px 0;
    }

    .loyalty_points_faqs_container .accordion .accordion_item h3.accordion_item_header {
        font-size: 15px;
        line-height: 22px;
    }


}

@media(max-width:767px) {

    main#chargezen_main_affiliate_page_container .chargezen_page__content.rte h2,
    main#chargezen_main_affiliate_page_container .chargezen_page__content.rte h1 {
        font-size: 23px !important;
        letter-spacing: -2.1px !important;
    }

    .how_to_earn_commission_container .earn_commission_list_wrapper {
        flex-wrap: wrap;
    }

    .how_to_earn_commission_container .earn_commission_list_wrapper>.earn_commission_list {
        min-width: 150px;
    }

    .how_to_earn_commission_container .earn_commission_list_wrapper>.earn_commission_list {
        min-width: calc(100%/5 - 25px);
        flex: calc(100%/5 - 25px);
    }



    .how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list p::after {
        right: -5px;
        top: 11%;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid #c5c5c5;
    }

    main#chargezen_main_affiliate_page_container .chargezen_page__content .main_header_wrapper {
        max-width: 100% !important;
        box-sizing: border-box;
        padding: 35px 20px 35px 20px !important;
    }

    main#chargezen_main_affiliate_page_container .chargezen_page__content.rte {
        padding: 0 10px 90px;
    }

    main#chargezen_main_affiliate_page_container .chargezen_page__content .main_header_content_wrapper p {
        font-size: 14px;
        line-height: 15px;
        margin: 8px auto 0;
        max-width: 250px;
    }

    .how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list .icon_wrapper svg {
        height: 30px;
        width: 30px;
    }

    .how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list p {
        font-size: 8px;
        line-height: 10px;
        position: relative;
        min-height: 34px !important;
    }

    .how_to_earn_commission_container .earn_commission_list_wrapper {
        padding: 20px 15px;

        gap: 10px;
    }

    .how_to_earn_commission_container .earn_commission_list_wrapper .earn_commission_list .text_wrapper .get_your_commission_button {
        font-size: 6px;
        padding: 9px 3px;
        margin-top: 10px;
    }

    .how_to_earn_commission_container h3 {
        padding: 3px 0;
        font-size: 16px;
    }

    main#chargezen_main_affiliate_page_container .chargezen_page__content.rte h2 {
        font-size: 20px !important;
        margin-bottom: 1px;
        letter-spacing: -1.1px !important;
    }

    main#chargezen_main_affiliate_page_container .chargezen_page__content.rte h1 {
        font-size: 27px !important;
        letter-spacing: -0.8px !important;
    }

}